import './HeroArrow.scss';
import ArrowDown from '../../assets/icons/arrow-down-green.svg';

const HeroArrow = ( props ) => {
    return (
        <div className="HeroArrow" >
        <a href={props.anchorId}><img src={ArrowDown} alt="logo"/></a> 
        </div>
    )
}

export default HeroArrow;
