
import React from 'react';
import { HashLink as NavLink } from 'react-router-hash-link';

import './Footer.scss';
import LogoR from '../../assets/R_Verde.png';
import LogoTitle from '../../assets/logo_verde_letras.png';
import linkedin from '../../assets/icons/linkedin.svg';
import doctoralia from '../../assets/icons/doctoraliawhite.png';
import instagram from '../../assets/icons/instagram.svg';
import gmail from '../../assets/icons/gmail.svg';
import whatsapp from '../../assets/icons/whatsapp.svg';


function Footer ({year}) {

    return (
        
        <div className='Footer'>
            <div className="Footer__content">
                <div className="Footer__r">
                    <img src={LogoR} alt="footer_r" />
                </div>
                <ul className="Footer__menu">
                    <li class="list-item"><NavLink to="/about#about">CONÓCEME</NavLink></li>
                    <li class="list-item"><NavLink to="/publications#publications">PUBLICACIONES</NavLink></li>
                    <li class="list-item"><NavLink to="/home#servicios">SERVICIOS</NavLink></li>
                    <li class="list-item"><NavLink to="/contact#contact">CONTACTO</NavLink></li>
                </ul>
                <div className="Footer__logotitle">
                    <img src={LogoTitle} alt="footer_logotitle" />
                </div>
                <div className="Footer__social">
                    <a href="https://www.instagram.com/roshninutricion/?hl=es" target="_blank" rel="noopener noreferrer" className="social-item"><img src={instagram} alt="instagram"/></a>
                    <a href="https://www.linkedin.com/in/roshni-peswani-8057604b/?originalSubdomain=es" target="_blank" rel="noopener noreferrer" className="social-item"><img src={linkedin} alt="linkedin"/></a>
                    <a href="https://www.doctoralia.es/roshni-peswani-peswani/dietista-nutricionista/maspalomas" target="_blank" rel="noopener noreferrer" className="social-item"><img src={doctoralia} alt="doctoralia"/></a>
                </div>
                <div className="Footer__contactMe">
                    <h3>Contacto</h3>
                    <a href="mailto:roshninutricion@gmail.com?subject=Solicitud%20de%20cita:" className="Footer__contactMe-email">
                        <img src={gmail} alt="gmail"/>
                        <p>roshninutricion@gmail.com</p>
                    </a>
                    <a className="Footer__contactMe-whatsApp" target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=34644011842">
                        <img src={whatsapp} alt="whatsApp"/>
                        <p>WhatsApp</p>
                    </a>
                </div>  
            </div>
            <span className="Footer__createdBy"><p>Website created by <a href="https://www.aridanemartin.dev" target="_blank" rel="noreferrer">Aridane Martín</a> &#169; {year}</p></span>

        </div>



    );
}

export default Footer;