// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

import "./Slider.scss";

import Stomach from '../../assets/icons/stomachpurple.png';
import MeasureTape from '../../assets/icons/tape-measurepurple.png';
import SistemaInmune from '../../assets/icons/sistema-inmunepurple.png';
import Intolerancias from '../../assets/icons/glutenpurple.png';
import Diabetes from '../../assets/icons/diabetespurple.png';

// import Swiper core and required modules
import SwiperCore, {
  Pagination,Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination,Navigation]);


export default function Slider() {
  
  
  
  return (
    <div className="Slider" id="servicesanchor">
      
      <div class="custom-shape-divider-bottom-1626950069">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
      </svg>
      </div>


      <div className="SliderBackground">
        
        

        <div className="SliderContainer">
          
          <>
            <Swiper 
                pagination={{ clickable: false }}
                navigation={true}
                spaceBetween={30}
                slidesPerView={1}
                // slidesPerView={'auto'}
                className="mySwiper"

                breakpoints={{
                  // when window width is >= 640px
                  320: {
                    width: 320,
                    slidesPerView: 1,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 2,
                  },
                  1024: {
                    width: 1024,
                    slidesPerView: 3,
                  },
                }}
            >
              <SwiperSlide>
                <h3>Salud Digestiva</h3>
                <img src={Stomach} alt="Salud Digestiva || Las Palmas"></img>
                <ul>
                  <li>Digestiones lentas,<br/>hinchazón abdominal</li>
                  <li>Gastritis / Reflujo</li>
                  <li>Diarrea / Estreñimiento</li>
                  <li>Sobrecrecimiento bacteriano</li>
                </ul>
              </SwiperSlide>
              <SwiperSlide>
              <h3>Intolerancias Alimentarias</h3>
                <img src={Intolerancias} alt="Intolerancias alimentarias || Las Palmas"></img>
                <ul>
                  <li>Lactosa</li>
                  <li>Fructosa / Sorbitol</li>
                  <li>Gluten</li>
                  <li>Histamina</li>
                  <li></li>
                </ul>
              </SwiperSlide>
              <SwiperSlide>
              <h3>Enfermedades Autoinmunes</h3>
                <img src={SistemaInmune} alt="Enfermedades Autoinmunes || Las Palmas"></img>
                <ul>
                  <li>Hipotiroidismo Hashimoto</li>
                  <li>Artritis Reumatoide</li>
                  <li>Psoriasis</li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </SwiperSlide>
              <SwiperSlide>
              <h3>Sindrome metabólico</h3>
                <img src={Diabetes} alt="Cuidado de la diabetes || Las Palmas"></img>
                <ul>
                  <li>Diabetes</li>
                  <li>Obesidad</li>
                  <li>HTA</li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </SwiperSlide>
              <SwiperSlide>
              <h3>Composición Corporal</h3>
                <img src={MeasureTape} alt="Pérdidad de Peso || Las Palmas"></img>
                <ul>
                  <li>Perdida de grasa</li>
                  <li>Aumento de peso<br/><br/><br/></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </SwiperSlide>
            </Swiper>
          </>
        </div>
      </div>

    <div class="custom-shape-divider-top-1626950293">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
      </svg>
    </div>          





    </div>
  )
}