import './ContactForm.scss';
import { useForm } from '../../hooks/useForm';

import RoshniVertical from '../../assets/perfil.jpg';

const initialForm = {
    name: "",
    email: "",
    message: ""
};

const validationsForm = (form) => {
    let errors = {};
    let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/; 
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexMessage = /^.{1,300}$/;

    if(!form.name.trim()) {
        errors.name = "El campo 'Nombre' es requerido";
    }else if(!regexName.test(form.name.trim())) {
        errors.name = "El campo 'Nombre' solo acepta letras y espacios en blanco";
    }

    if(!form.email.trim()) {
        errors.email = "El campo 'Email' es requerido";
    }else if(!regexEmail.test(form.email.trim())) {
        errors.email = "El 'Email' debe tener un formato válido";
    }

    if(!form.message.trim()) {
        errors.message = "El campo 'Mensaje' es requerido";
    }else if(!regexMessage.test(form.message.trim())) {
        errors.message = "El campo 'Mensaje' tiene un máximo de 300 caracteres";
    }

    return errors;
}


const ContactForm = () => {

    const {
        form,
        errors,
        loading,
        handleChange,
        handleBlur,
        handleSubmit
    } = useForm(initialForm, validationsForm);

    return(
        <section className="Contact__form-section">
                <form 
                        onSubmit={handleSubmit} 
                        className="Contact__form"
                        >
                            <h3>Contacto</h3>
                            <input 
                                className="Contact__form-name" 
                                type="text" 
                                placeholder="Nombre y Apellidos"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={form.name}
                                required
                            ></input>
                            {errors.name && <p>{errors.name}</p>}
                            <input 
                                className="Contact__form-name" 
                                type="email" 
                                placeholder="email@ejemplo.com"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={form.email}
                                required
                            ></input>
                            {errors.email && <p>{errors.email}</p>}
                            <textarea 
                                rows="5"
                                className="Contact__form-msg"
                                type="text" 
                                placeholder="Escribe aquí tu mensaje"
                                name="message"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={form.message}
                                required
                            ></textarea>
                            {errors.message && <p>{errors.message}</p>}
                            <input 
                            className="Contact__form-submit" 
                            type="submit" 
                            name="submit" 
                            value="Enviar"
                            ></input>
                            {loading && <p className="Contact__form-sended">Su mensaje ha sido enviado</p>}
                </form>
                <img src={RoshniVertical} 
                className="About__img" 
                alt="Roshni Peswani || Nutricionista - Dietista en Las Palmas"/>
            </section>

    )
}

export default ContactForm;