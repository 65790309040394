import React from 'react';

import './Testimonials.scss';
import rightQuote from '../../assets/icons/rightquote.png';
import { motion, AnimatePresence } from 'framer-motion';

export default function Testimonials({ testimonials }) {
  
  const [count, setCount] = React.useState(generateRandom());

  const maxNumber = testimonials.length;
  function generateRandom (){
    let maxNumber = testimonials.length;
    let randomNumber = Math.floor(Math.random() * maxNumber);
    return randomNumber;
  }

  function nextTestimonial (){
    if((count + 1) >= maxNumber){ 
      setCount(0);
    }else{
      setCount(count + 1);
    }
  }
  
  return (
      <div className="TestimonialsBackground">
        <div className="TestimonialsContainer">
          <div className="quotes">
            <img src={rightQuote} alt="right quote"></img>
          </div>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={count}
              initial={{ opacity: 0, y:20}}
              animate={{ opacity: 1, y:0 }}
              transition={{ duration: 0.15 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <h4 className="selectedName">
                {testimonials[count].author}
              </h4>
              <p className="selectedTestimonial">
                {testimonials[count].testimonial}
              </p>
            </motion.div>
          </AnimatePresence>
          <button className="button"onClick={nextTestimonial}>Leer más testimonios</button>
          <p className="doctoralia">Testimonios extraídos del perfil profesional de Roshni Peswani alojado en la web <a href="https://www.doctoralia.es/roshni-peswani-peswani/dietista-nutricionista/maspalomas">www.doctoralia.es</a></p>
        </div>
      </div>

    
  )


}

