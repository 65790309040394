import './About.scss';
import CV from '../../components/CV/CV';
import Helmet from 'react-helmet';

const About = () => {
    return(

        <div className="About__container">
        <Helmet>
            <title>Conóceme || Roshni P. Dietista - Nutricionista en Las Palmas</title>
            <link rel="canonical" href="http://roshninutricion.com/about" />
            <meta name="description" content="Soy Roshni, Nutricionista-Dietista titulada por la Universidad Politécnica de Madrid con más de 10 años de experiencia en Las Palmas de Gran Canaria."/>

            <meta itemprop="name" content="Conóceme || Roshni P. Dietista - Nutricionista en Las Palmas"/>
            <meta itemprop="description" content="Soy Roshni, Nutricionista-Dietista titulada por la Universidad Politécnica de Madrid con más de 10 años de experiencia en Las Palmas de Gran Canaria."/>
            <meta itemprop="image" content="https://www.roshninutricion.com/static/media/R_Verde.447e5fc9.png" />

            <meta property="og:url" content="https://www.roshninutricion.com"/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="Conóceme || Roshni P. Dietista - Nutricionista en Las Palmas"/>
            <meta property="og:description" content="Soy Roshni, Nutricionista-Dietista titulada por la Universidad Politécnica de Madrid con más de 10 años de experiencia en Las Palmas de Gran Canaria."/>
            <meta property="og:image:secure" content="https://www.roshninutricion.com/static/media/R_Verde.447e5fc9.png" />

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content="Conóceme || Roshni P. Dietista - Nutricionista en Las Palmas"/>
            <meta name="twitter:description" content="Soy Roshni, Nutricionista-Dietista titulada por la Universidad Politécnica de Madrid con más de 10 años de experiencia en Las Palmas de Gran Canaria."/>
            <meta name="twitter:image" content="https://www.roshninutricion.com/static/media/R_Verde.447e5fc9.png" />

            <meta name="keywords" content="dietista, nutricionista, las palmas, las palmas de gran canaria, dieta saludable, bajar de peso, problemas de digestión, problemas digestivos"/>
        </Helmet>
        <CV />


        </div>
    )
}

export default About;