import './Publications.scss';
import Articleleft from '../../components/Articleleft/Articleleft';
import Articleright from '../../components/Articleright/Articleright';
import HeroArrow from '../../components/HeroArrow/HeroArrow';
import Helmet from 'react-helmet';

// Imagenes articulos
import article0img from '../../assets/article0.webp';
import article1img from '../../assets/article1.webp';
import article2img from '../../assets/article2.webp';
import article3img from '../../assets/article3.webp';

import { FAKE_DB } from '../../fakeDB/fakeDB';
import Separador from '../../components/Separador/Separador';
const { articles, separadores } = FAKE_DB; // Desestructuracion

const Publications = () => {
    return(
        <div id="publications" className="Publications__container">
            <Helmet>
                <title>Publicaciones || Roshni P. Dietista - Nutricionista en Las Palmas</title>
                <link rel="canonical" href="http://roshninutricion.com" />
                <meta name="description" content="Dietista - Nutricionista especializada en patologías digestivas en Las Palmas de Gran Canaria. Trato a pacientes que necesitan tratar: Salud Digestiva, Intolerancias Alimentarias, Enfermedades Autoinmunes, Síndrome Metabólico, Composición Corporal..."/>

                <meta itemprop="name" content="Publicaciones || Roshni P. Dietista - Nutricionista en Las Palmas"/>
                <meta itemprop="description" content="Dietista - Nutricionista especializada en patologías digestivas en Las Palmas de Gran Canaria. Trato a pacientes que necesitan tratar: Salud Digestiva, Intolerancias Alimentarias, Enfermedades Autoinmunes, Síndrome Metabólico, Composición Corporal..."/>
                <meta itemprop="image" content="https://www.roshninutricion.com/static/media/R_Verde.447e5fc9.png" />

                <meta property="og:url" content="https://www.roshninutricion.com"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Publicaciones || Roshni P. Dietista - Nutricionista en Las Palmas"/>
                <meta property="og:description" content="Dietista - Nutricionista especializada en patologías digestivas en Las Palmas de Gran Canaria. Trato a pacientes que necesitan tratar: Salud Digestiva, Intolerancias Alimentarias, Enfermedades Autoinmunes, Síndrome Metabólico, Composición Corporal..."/>
                <meta property="og:image:secure" content="https://www.roshninutricion.com/static/media/R_Verde.447e5fc9.png" />

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="Publicaciones || Roshni P. Dietista - Nutricionista en Las Palmas"/>
                <meta name="twitter:description" content="Dietista - Nutricionista especializada en patologías digestivas en Las Palmas de Gran Canaria. Trato a pacientes que necesitan tratar: Salud Digestiva, Intolerancias Alimentarias, Enfermedades Autoinmunes, Síndrome Metabólico, Composición Corporal..."/>
                <meta name="twitter:image" content="https://www.roshninutricion.com/static/media/R_Verde.447e5fc9.png" />

                <meta name="keywords" content="dietista, nutricionista, las palmas, las palmas de gran canaria, dieta saludable, bajar de peso, problemas de digestión, problemas digestivos"/>
            </Helmet>
            <Separador separadores={separadores[4]}/>
            <HeroArrow anchorId="#publications"/>
            <Articleleft articles={articles[2]} imagen={article2img} />
            <Articleright articles={articles[3]} imagen={article3img} />
            <Articleleft articles={articles[0]} imagen={article0img} />
            <Articleright articles={articles[1]} imagen={article1img} />
        </div>
    )
}

export default Publications;