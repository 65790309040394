import './Nav.scss';

import R from '../../assets/R_Verde.png';
import linkedin from '../../assets/icons/linkedin.svg';
import doctoralia from '../../assets/icons/doctoraliawhite.png';
import instagram from '../../assets/icons/instagram.svg';
// import { Link } from 'react-router-dom';
import { HashLink as NavLink } from 'react-router-hash-link';

const Nav = () => {

    
    // Nav animation HIDE ON SCROLL!

    let prevScrollpos = window.pageYOffset;
    window.onscroll = () => {
      let currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.querySelector(".nav").style.top = "0";
      } else {
        document.querySelector(".nav").style.top = "-400px";
      }
      prevScrollpos = currentScrollPos;
    }
    

    ////////////////////////////////////////////

    
    function handleShow() {
      const toggleButton = document.querySelector('.burger');
      const menu = document.querySelector('.nav__menu');
      
      if(toggleButton.classList.contains('open')){
        toggleButton.classList.remove('open');
        menu.classList.add('hide');
        window.removeEventListener('scroll', noScroll);
      }else{
        toggleButton.classList.add('open');
        menu.classList.add('show');
        menu.classList.remove('hide');
        window.addEventListener('scroll', noScroll);
      }
    }

    function closeAfterClick(){
      const toggleButton = document.querySelector('.burger');
      const menu = document.querySelector('.nav__menu');
      toggleButton.classList.remove('open');
      menu.classList.add('hide');
      window.removeEventListener('scroll', noScroll);
    }

    function noScroll() {
      window.scrollTo(0, 0);
  }
    
    return(
    <nav className="nav">
        <div className="nav__logo">
            <a id="logo" href="./home"><img src={R} alt="R" /></a>
            <button onClick={handleShow} className="burger" id="burger">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </button>
            <ul className="nav__menu" id="menu">
            <li><a className="nav__r" href="./home"><img src={R} alt="R" /></a></li>
            <li className="menu-item"><NavLink to="/about#about" className="menu-link" onClick={closeAfterClick}>CONÓCEME</NavLink></li>
            <li className="menu-item"><NavLink to="/publications#publications" className="menu-link" onClick={closeAfterClick}>PUBLICACIONES</NavLink></li>
            <li className="menu-item"><NavLink to='/home#servicios' className="menu-link" onClick={closeAfterClick}>SERVICIOS</NavLink></li>
            <li className="menu-item"><NavLink to="/contact#contact" className="menu-link" onClick={closeAfterClick}>CONTACTO</NavLink></li>
        </ul>
        </div>
        
        <div className="nav__social">
            <a href="https://www.instagram.com/roshninutricion/?hl=es" target="_blank" rel="noopener noreferrer" className="social-item"><img src={instagram} alt="instagram"/></a>
            <a href="https://www.linkedin.com/in/roshni-peswani-8057604b/?originalSubdomain=es" target="_blank" rel="noopener noreferrer" className="social-item"><img src={linkedin} alt="linkedin"/></a>
            <a href="https://www.doctoralia.es/roshni-peswani-peswani/dietista-nutricionista/maspalomas" target="_blank" rel="noopener noreferrer" className="social-item"><img src={doctoralia} alt="doctoralia"/></a>
        </div>
    </nav>
    );
};

export default Nav;