import './Articlerigth.scss';

const Articlerigth = ({ articles, imagen }) => {

    return(
        <div className="articleright">
            <div className="article__content">
                <h1 className="article__title">{articles.title}</h1>
                <a className="article__url" href={articles.url} target="_blank" rel='noreferrer noopener'>{articles.author}</a>
                <p className="article__text">{articles.description}</p>
                <a href={articles.url} target="_blank" rel='noreferrer noopener' className="article__keepreading">Continuar leyendo...</a>
            </div>
            <img className="article__img" alt={articles.title} src={imagen}/>
        </div>
    )
}

export default Articlerigth;

