import './Home.scss';
import perfil from '../../assets/perfil.jpg';
import Articleleft from '../../components/Articleleft/Articleleft';
import Articleright from '../../components/Articleright/Articleright';
import Slider from '../../components/Slider/Slider';
import Separador from '../../components/Separador/Separador';
import Testimonials from '../../components/Testimonials/Testimonials';
import HeroArrow from '../../components/HeroArrow/HeroArrow.js';
import LinkButton from '../../components/LinkButton/LinkButton';
import Helmet from 'react-helmet';



// Images //
import CardenalHerrera from '../../assets/logos_bio/cardenalherreralogo.svg';
import CentroCime from '../../assets/logos_bio/centrocime.png';
import Complutense from '../../assets/logos_bio/complutense.png';
import Fresenius from '../../assets/logos_bio/freseniuskabi.svg';
import Uned from '../../assets/logos_bio/unedlogo.svg';
// import SanRoque from '../../assets/logos_bio/hospitalessanroque.png';
import Hps from '../../assets/logos_bio/HPS.jpg';
import React from 'react';

// Imagenes articulos
import article0img from '../../assets/article0.webp';
import article1img from '../../assets/article1.webp';

// Base de datos //
import { FAKE_DB } from '../../fakeDB/fakeDB';
const { articles, separadores, testimonials } = FAKE_DB; // Desestructuracion


const Home = () => {

    return(
        <React.Fragment>
        <Helmet>
                <title>Home || Roshni P. Dietista - Nutricionista en Las Palmas</title>
                <link rel="canonical" href="http://roshninutricion.com" />
                <meta name="description" content="Dietista - Nutricionista especializada en patologías digestivas en Las Palmas de Gran Canaria. Trato a pacientes que necesitan tratar: Salud Digestiva, Intolerancias Alimentarias, Enfermedades Autoinmunes, Síndrome Metabólico, Composición Corporal..."/>

                <meta itemprop="name" content="Home || Roshni P. Dietista - Nutricionista en Las Palmas"/>
                <meta itemprop="description" content="Dietista - Nutricionista especializada en patologías digestivas en Las Palmas de Gran Canaria. Trato a pacientes que necesitan tratar: Salud Digestiva, Intolerancias Alimentarias, Enfermedades Autoinmunes, Síndrome Metabólico, Composición Corporal..."/>
                <meta itemprop="image" content="https://www.roshninutricion.com/static/media/R_Verde.447e5fc9.png" />

                <meta property="og:url" content="https://www.roshninutricion.com"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Home || Roshni P. Dietista - Nutricionista en Las Palmas"/>
                <meta property="og:description" content="Dietista - Nutricionista especializada en patologías digestivas en Las Palmas de Gran Canaria. Trato a pacientes que necesitan tratar: Salud Digestiva, Intolerancias Alimentarias, Enfermedades Autoinmunes, Síndrome Metabólico, Composición Corporal..."/>
                <meta property="og:image:secure" content="https://www.roshninutricion.com/static/media/R_Verde.447e5fc9.png" />

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="Home || Roshni P. Dietista - Nutricionista en Las Palmas"/>
                <meta name="twitter:description" content="Dietista - Nutricionista especializada en patologías digestivas en Las Palmas de Gran Canaria. Trato a pacientes que necesitan tratar: Salud Digestiva, Intolerancias Alimentarias, Enfermedades Autoinmunes, Síndrome Metabólico, Composición Corporal..."/>
                <meta name="twitter:image" content="https://www.roshninutricion.com/static/media/R_Verde.447e5fc9.png" />

                <meta name="keywords" content="dietista, nutricionista, las palmas, las palmas de gran canaria, dieta saludable, bajar de peso, problemas de digestión, problemas digestivos"/>
        </Helmet>

        <HeroArrow anchorId="#bio" />

        <div className="Bio" id="bio">
            <img className="Bio__image" src={perfil} alt="Roshni P. || Tu nutricionista en Las Palmas"/>
            <h1 className="Bio__title">¡Hola! Soy Roshni</h1>
            <p className="Bio__text">Dietista-Nutricionista especializada en patologías digestivas en Las Palmas de Gran Canaria.
            Ayudo a mis pacientes a alcanzar un estado de salud óptimo, desde un punto de vista integrativo y con un enfoque multidisciplinar.
            </p>
            <LinkButton text={"¿Quieres conocerme mejor?"} link={'/about#about'}/>
            <div className="Bio__logos">
                {/* <img className="Bio__logo" src={SanRoque} alt="San Roque"/> */}
                <img className="Bio__logo" src={Hps} alt="Hps"/>
                <img className="Bio__logo" src={CentroCime} alt="Centro Cime"/>
                <img className="Bio__logo" src={Fresenius} alt="Fresenius"/>
                <img className="Bio__logo" src={CardenalHerrera} alt="CH"/>
                <img className="Bio__logo" src={Uned} alt="Uned"/>
                <img className="Bio__logo" src={Complutense} alt="Complutense"/>
            </div>  
        </div>
        
        <Separador separadores={separadores[0]}/>
        <div id="servicios">
            <Slider />
        </div>
        <Separador separadores={separadores[1]}/>
        <Articleleft articles={articles[0]} imagen={article0img} />
        <Articleright articles={articles[1]} imagen={article1img} />

        <Separador separadores={separadores[2]}/>
        <Testimonials testimonials={testimonials}/>
        
        
        
            
        </React.Fragment>
        

        
    )
}

export default Home;

