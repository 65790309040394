import './Contact.scss';
import ContactForm from '../../components/ContactForm/ContactForm';
import HeroArrow from '../../components/HeroArrow/HeroArrow';
import Helmet from 'react-helmet';

const Contact = () => {
    return(
        <>
            <Helmet>
                    <title>Contacto || Roshni P. Dietista - Nutricionista en Las Palmas</title>
                    <link rel="canonical" href="http://roshninutricion.com/contacto" />
                    <meta name="description" content="¿Te gustaría concertar una visita? ¿quizás concertar una consulta de nutrición online? ¿simplemente quieres decir '¡Hola!'? Te invito a que rellenes este formulario para concertar nuestra primera visita presencial u online."/>

                    <meta itemprop="name" content="Contacto || Roshni P. Dietista - Nutricionista en Las Palmas"/>
                    <meta itemprop="description" content="¿Te gustaría concertar una visita? ¿quizás concertar una consulta de nutrición online? ¿simplemente quieres decir '¡Hola!'? Te invito a que rellenes este formulario para concertar nuestra primera visita presencial u online."/>
                    <meta itemprop="image" content="https://www.roshninutricion.com/static/media/R_Verde.447e5fc9.png" />

                    <meta property="og:url" content="https://www.roshninutricion.com"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Contacto || Roshni P. Dietista - Nutricionista en Las Palmas"/>
                    <meta property="og:description" content="¿Te gustaría concertar una visita? ¿quizás concertar una consulta de nutrición online? ¿simplemente quieres decir '¡Hola!'? Te invito a que rellenes este formulario para concertar nuestra primera visita presencial u online."/>
                    <meta property="og:image:secure" content="https://www.roshninutricion.com/static/media/R_Verde.447e5fc9.png" />

                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:title" content="Contacto || Roshni P. Dietista - Nutricionista en Las Palmas"/>
                    <meta name="twitter:description" content="¿Te gustaría concertar una visita? ¿quizás concertar una consulta de nutrición online? ¿simplemente quieres decir '¡Hola!'? Te invito a que rellenes este formulario para concertar nuestra primera visita presencial u online."/>
                    <meta name="twitter:image" content="https://www.roshninutricion.com/static/media/R_Verde.447e5fc9.png" />

                    <meta name="keywords" content="dietista, nutricionista, las palmas, las palmas de gran canaria, dieta saludable, bajar de peso, problemas de digestión, problemas digestivos"/>
            </Helmet>
            <div id="contact" className="Contact__container">
                <HeroArrow anchorId="#contact" />
                <ContactForm />
            </div>
        </>
    )
}

export default Contact;