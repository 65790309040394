export const FAKE_DB = {
    articles: [
        {
            title: "¿Son útiles las apps de nutrición?",
            author: "Roshni Peswani | Colaboración con Spar & Hospitales San Roque",
            url: "https://spargrancanaria.es/son-utiles-las-apps-de-nutricion/",
            description: "Probablemente hayas visto en el supermercado, gente apuntando con el móvil el"
            + " código de barras de los paquetes de algunos alimentos, y te preguntarás qué están "
            + "haciendo. Están usando una app para valorar los productos que van a meter en su cesta "
            + "de la compra. Estas apps dan una puntuación al producto en base a su composición "
            + "nutricional, nivel de procesamiento, aditivos, etc."
        },
        {
            title: "Alimentos Ecológicos y Bio",
            author: "Roshni Peswani | Colaboración con Spar & Hospitales San Roque",
            url: "https://spargrancanaria.es/alimentos-ecologicos-y-bio/",
            description: "Vivimos en una sociedad cada vez más concienciada con el "
            + "cuidado del medio ambiente. En este sentido se abre paso un nuevo "
            + "mundo en nuestro día a día, el de los denominados productos ecológicos "
            + "o bio, que son cada vez más frecuentes en los supermercados. Sin embargo, "
            + "¿sabemos exactamente qué son los productos ecológicos o bio? ¿Conocemos "
            + "los requisitos que deben de cumplir para considerarse como tal?"
        },
        {
            title: "Gastronomía canaria y nutrición",
            author: "Roshni Peswani | Colaboración con Spar & Hospitales San Roque",
            url: "https://spargrancanaria.es/gastronomia-canaria-y-nutricion/",
            description: "Dicen que no terminas de conocer un lugar hasta que no conoces "
            + "su gastronomía, pues es parte importante de su historia e identidad. La "
            + "gastronomía canaria nace de la combinación de un clima templado y de una "
            + "tierra volcánica que le da un sabor especial, con influencias de la Península "
            + "Ibérica, de otros países europeos como Inglaterra, del Norte de África y de "
            + "Sudamérica. Contamos con ingredientes tan característicos como el gofio, el millo, "
            + "la papa, el plátano, pescados como la vieja, mero o cherne, y algunos quesos o vinos... "
        },
        {
            title: "Lactancia materna y nutrición",
            author: "Roshni Peswani | Colaboración con Spar & Hospitales San Roque",
            url: "https://spargrancanaria.es/lactancia-materna-y-nutricion/",
            description: "Dicen que no terminas de conocer un lugar hasta que no conoces "
            + "La lactancia materna es considerada el método de referencia para la alimentación "
            + "del lactante durante los primeros seis meses de vida, pues cubre las necesidades "
            + "nutricionales para su adecuado crecimiento y desarrollo. Durante este periodo, "
            + "la madre no necesita una dieta especial, simplemente comer de forma variada y "
            + "equilibrada, puesto que, salvo desnutrición clara u otras situaciones muy "
            + "específicas, el estado nutricional de la madre no interfiere en la producción "
            + "láctea ni en la calidad de la leche."
        }
    ],
    separadores: [
        {
            title:"¿A qué me dedico?",
            text:"Trabajo conjuntamente con mi equipo en ayudar a nuestros pacientes "
            + "a mejorar su salud, de una manera integrativa y siempre escuchando las "
            + "señales de nuestro cuerpo."
        },
        {
            title:"Artículos Publicados",
            text:"A continuación te muestro algunos de los últimos artículos sobre nutrición y dietética "
            + "que he publicado como colaboración para distintas empresas o entidades."
        },
        {
            title:"Testimonios",
            text:"Estas son algunas opiniones de mis pacientes. Les agradezco su compromiso para superar sus problemas digestivos."
        },
        {
            title:"Conóceme",
            text:""
        },
        {
            title:"Roshni en los medios",
            text:"Conoce más acerca de las publicaciones de Roshni en los medios. Aquí encontrarás información sobre nutrición, dietética, cuidado de enfermedades, curiosidades..."
        },
    ],
    testimonials: [
        {
            author: "L.S.",
            testimonial: "Es una gran profesional, con un trato muy cercano, "
            + "busca siempre soluciones para que el paciente se sienta cómodo "
            + "con la dieta que lleva a cabo y te da variedad de platos y recetas "
            + "para tener. En mi caso fui por que tengo Colon irritable y además "
            + "quería bajar 10 kilos, consiguiendo buenos resultados en ambos casos, "
            + "así que muy contenta..."

        },
        {
            author: "Aroa",
            testimonial: "Roshni es una nutricionista de 10. Llevo toda mi vida luchando "
            + "con el peso y por primera vez he conseguido aprender a comer y he obtenido "
            + "unos hábitos que ya forman parte de mi vida. Ya no me siento con esa presión "
            + "de estar haciendo dieta. He seguido sus pautas y me ha ido genial. Como profesional "
            + "es estupenda, te dedica el tiempo necesario y se preocupa por ayudarte y animarte "
            + "a seguir."
        },
        {
            author: "Sole Mederos",
            testimonial: "Roshni es una profesional excelente, no sólo por la empatía "
            + "que muestra con el paciente, sino que además te ayuda con muchísima "
            + "información, haciendo que la dieta se convierta en un reto del que no "
            + "podrás escapar. Con ella logré resolver todos los problemas ocasionados "
            + "por diversas intolerancias y aprendí a comer de forma saludable. Un "
            + "encanto de persona que se deja la piel por lograr solucionar el problema "
            + "del paciente . La seguiré visitando y recomendando 100%!!"
        },
        {
            author: "Ángeles",
            testimonial: "Es una Nutricionista excelente. No sólo me ha facilitado "
            + "documentación (dietas, recetas, recomendaciones sobre hábitos de vida "
            + "saludables,...), sino que me ha enseñado a comer de manera saludable y a "
            + "incorporarlo a mi forma de vida. Ha sido un apoyo muy importante durante mi "
            + "dieta, proporcionándome herramientas que me ayudarán en el futuro. Muchas "
            + "gracias Roshni, por tu cercanía, empatía y profesionalidad."
        },
        {
            author: "Stella",
            testimonial: "Fui a consulta recomendada por mi endocrino el Dr.Tamayo y "
            + "la verdad salí encantada de la consulta. Es muy buena profesional, "
            + "empática, paciente, agradable y explica todo muy bien para que no tengas "
            + "dudas. Hace que veas la forma de mejorar tu estilo de vida a nivel de "
            + "alimentación no como un suplicio, sino como un cambio a mejor. Sin duda la "
            + "recomiendo como dietista, de hecho ya varias personas de mi familia acuden a "
            + "su consulta tras mi primera experiencia."
        }
        ,
        {
            author: "Celia F.",
            testimonial: "He seguido tratamiento con Roshni y he conseguido los objetivos "
            + "de salud y peso que me propuso en un tiempo récord. Estoy encantada porque "
            + "además de su incuestionable profesionalidad, su cortesía y amabilidad hicieron "
            + "que todo fuera muy fácil. Las personas que me han visto después del tratamiento "
            + "lo han notado para bien. Sin dudarlo, la recomiendo."
        }
    ] 
}