import './Separador.scss';

const Separador = ({ separadores }) => {
    return(
        <div className="Separador">
            <h2 className="Separador__title">{separadores.title}</h2>
            <p className="Separador__text">{separadores.text}</p>
        </div>
    )
}

export default Separador;