import './App.scss';
import Hero from './components/Hero/Hero';
import Nav from './components/Nav/Nav';
import Footer from './components/Footer/Footer';
import Footercurve from './components/Footercurve/Footercurve';

import { Helmet } from 'react-helmet';


/// Pages ///

import Home from './pages/Home/Home';
import About from './pages/About/About';
import Publications from './pages/Publications/Publications';
import Contact from './pages/Contact/Contact';

/// Router ///
import { 
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';


const App = () => {

  const year = new Date().getFullYear();

  return (
        <div className="App">
            <Helmet>
                

                <title>Roshni Peswani || Dietista - Nutricionista en Las Palmas de GC</title>
                <link rel="canonical" href="http://roshninutricion.com" />
                <meta name="description" content="Apasionada de mi profesión, considero la nutrición como pilar fundamental para un estado de salud óptimo, siempre desde un punto de vista personalizado y con un enfoque multidisciplinar."/>

                <meta itemprop="name" content="Roshni Peswani || Dietista - Nutricionista en Las Palmas de Gran Canaria"/>
                <meta itemprop="description" content="Apasionada de mi profesión, considero la dietética y la nutrición como pilar fundamental para un estado de salud óptimo, siempre desde un punto de vista personalizado y con un enfoque multidisciplinar."/>
                <meta itemprop="image" content="https://www.roshninutricion.com/static/media/R_Verde.447e5fc9.png" />

                <meta property="og:url" content="https://www.roshninutricion.com"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Roshni Peswani || Dietista - Nutricionista en Las Palmas de Gran Canaria"/>
                <meta property="og:description" content="Apasionada de mi profesión, considero la dietética y la nutrición como pilar fundamental para un estado de salud óptimo, siempre desde un punto de vista personalizado y con un enfoque multidisciplinar."/>
                <meta property="og:image:secure" content="https://www.roshninutricion.com/static/media/R_Verde.447e5fc9.png" />

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="Roshni Peswani || Dietista - Nutricionista en Las Palmas de Gran Canaria"/>
                <meta name="twitter:description" content="Apasionada de mi profesión, considero la dietética y la nutrición como pilar fundamental para un estado de salud óptimo, siempre desde un punto de vista personalizado y con un enfoque multidisciplinar."/>
                <meta name="twitter:image" content="https://www.roshninutricion.com/static/media/R_Verde.447e5fc9.png" />

                <meta name="keywords" content="dietista, nutricionista, las palmas, las palmas de gran canaria, dieta saludable, bajar de peso, problemas de digestión, problemas digestivos"/>
            </Helmet>


            <Router>
              <Nav />
              <Hero />

              <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/home" component={Home} />
                  <Route path="/about" component={About} />
                  <Route path="/publications" component={Publications} />
                  <Route path="/contact" component={Contact} />
                  <Route path="*" component={Home} />
              </Switch>
              <Footercurve />
              <Footer
                year={year}
              />
            </Router>

        </div>
      
    
  );
}

export default App;
